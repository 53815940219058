<template>
    <div class="shopping-car">
        <van-checkbox-group v-model="result">
            <div class="goods-item">
                <van-checkbox name="a" class="check"></van-checkbox>
                <van-card
                    num="1"
                    price="56.00"
                    desc="水果"
                    title="周至猕猴桃"
                    :thumb="goodsUrl"
                />
            </div>
        </van-checkbox-group>
        <van-submit-bar :price="5600" button-text="提交订单" @submit="onSubmit">
            <van-checkbox v-model="checked">全选</van-checkbox>
           <!--  <template #tip>
                你的收货地址不支持同城送, <span @click="onClickEditAddress">修改地址</span>
            </template> -->
        </van-submit-bar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            checked: true,
            result: ['a'],
            goodsUrl: require('@/assets/planA/orange.png')
            // goodsUrl: 'http://qg2n4993w.hn-bkt.clouddn.com/c8f9f58d-fa10-42cb-9979-208d86ad7da0'
        }
    },
    methods: {
        onSubmit() {

        },
        onClickEditAddress() {

        }
    }
}
</script>

<style lang="scss" scoped>
    .shopping-car {
        padding: 10px;
        .van-card {
            padding-left: 40px;
            border-radius: 8px;
        }
        .goods-item {
            position: relative;
        }
        .check {
            position: absolute;
            top: 40px;
            left: 10px;
            z-index: 9;
        }
    }
</style>
<style lang="scss">
    .shopping-car {
        .van-checkbox__icon--checked .van-icon {
            background-color: #ff6034;
            border-color: #ff6034;
        }
    }
</style>